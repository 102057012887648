body {
  height: 100dvh;
  overflow: hidden;
}

.truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

a, a:visited {
  color: var(--color-brand-grey-700);
  text-decoration: underline;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .menu a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


h2 {
  margin-top: var(--size-spacing-lg);
}

h3, h4, h5, p, blockquote {
  margin-top: var(--size-spacing-md);
}

[role="alert"] h3,
[role="alert"] h4,
[role="alert"] h5,
[role="alert"] p {
  margin-top: 0;
}

li {
  margin: var(--size-spacing-2xs) 0;
}

blockquote {
  background-color: var(--color-brand-grey-100);
  color: var(--color-brand-grey-700);
  padding: var(--size-spacing-md);
  border-width: 0 0 0 var(--size-border-md);
  border-color: var(--color-brand-grey-300);
  border-style: solid;
}

blockquote p:first-of-type,
blockquote h1:first-of-type,
blockquote h2:first-of-type,
blockquote h3:first-of-type,
blockquote h4:first-of-type {
  margin: 0;
  padding-top: 0;
}

table {
  border-collapse: collapse;
  min-width: 100%;
}

.anchor-link .anchor-link-icon {
  display: none;
}

.anchor-link:hover .anchor-link-icon {
  display: inline-block;
}

.skeletonBox {
  position: relative;
  overflow: hidden;
}

.skeletonBox::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nav-item, .nav-folder {
  padding: var(--size-spacing-xs) var(--size-spacing-xs);
  border-radius: var(--size-border-radius-sm);
}

.active,
.nav-item:hover {
  text-decoration: none;
  background-color: var(--color-brand-grey-50);
}

.hero {
  background-color: #182B2F;
  color: white;
  border-bottom: 1px solid #34373a;
}

.product-tile h4 {
  margin-top: 0;
}